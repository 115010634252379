@import "colors";
@import "misc";

.top-slider {
  overflow: hidden;

  .top-slider-wrap {
    width: 100vw;
    height: 25rem;
  
    display: flex;
    transition: transform .7s ease-in-out;
    
    & > div {
      color: white;
      height: 100%;
      width: 100vw;
      flex-shrink: 0;
      display: flex;
      // flex-direction: column;
      justify-content: center;
      align-items: center;
    
      background-size: cover;
      background-position: center;
    
      h1 {
        font-size: 350%;
        color: white;
        line-height: 1;

        @media (max-width: $mobile) {
          font-size: 250%;
        }
      }
    }
   
    .column-container {
      align-items: center;
      width: 100%;
    }

    .text-block {
      color: white;
      height: 100%;
      width: calc(50% - .6rem);
    
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    
      padding: 0 .3rem;
    
      h1 {
        font-size: 350%;
        color: white;
        margin-bottom: 0;
        text-align: center;
    
        line-height: 1;
    
        @media (max-width: $tablet) {
          font-size: 250%;
        }
      }
    
      img {
        height: 100%;
        width: 40%;
        max-width: 100rem;

        justify-self: center;
        
        @media (max-width: $tablet) {
          display: none;
        }
      }
    
      p {
        margin-bottom: 0;
        margin-top: 2rem;
    
        font-size: 130%;
        text-align: center;
        line-height: 1.2;
    
        @media (max-width: $tablet) {
          font-size: 110%;
        }
      }
      
      .btn {
        border-radius: 6px;
      }
      
      picture {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}